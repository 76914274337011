import { t } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import './Contact.scss'

import NewPageTitle from '../common/new-page-title/NewPageTitle'
import uweAlanderImg from '../../assets/images/contacts/Sales_Uwe_Alander.jpg'
import robertKnautImg from '../../assets/images/contacts/Costumer-Support_Robert_Knaut.jpg'
import janHildebrandtImg from '../../assets/images/contacts/Sales_Jan_Hildebrandt.jpg'
import annaSiewertImg from '../../assets/images/contacts/B2B-Marketing_Anna_Siewert.jpg'
import solveigSteinbrueckImg from '../../assets/images/contacts/InnerSales-Solveig_Steinbrueck.jpg'
import ericDubouaysImg from '../../assets/images/contacts/Sales_Eric_Dubouays.jpg'
import normanSteinkeImg from '../../assets/images/contacts/US-Sale_Norm_Steinke.jpg'
import christianLangnerImg from '../../assets/images/contacts/InnerSales_Christian_Langner.jpg'

const getContactRows = (language: string) => {
  switch (language) {
    case 'en':
      return [
        [
          {
            title: 'SALES EXPORT',
            name: 'Eric Dubouays',
            email: 'Eric.Dubouays@burmester.de',
            phoneNumber: '+49 30 79786869',
            img: ericDubouaysImg,
          },
          {
            title: 'SERVICE & CUSTOMER SUPPORT',
            name: 'Robert Knaut',
            email: 'Robert.Knaut@burmester.de',
            phoneNumber: '+49 30 78796896',
            img: robertKnautImg,
          },
        ],
        [
          {
            title: 'SALES NORTH AMERICA',
            name: 'Norman Steinke',
            email: 'Norman.Steinke@burmester.de',
            phoneNumber: '+49 30 79786869',
            img: normanSteinkeImg,
          },
          {
            title: 'B2B MARKETING',
            name: 'Anna Siewert',
            email: 'Anna.Siewert@burmester.de',
            phoneNumber: '+49 30 78796890',
            img: annaSiewertImg,
          },
        ],
        [
          {
            title: 'INSIDE SALES',
            name: 'Solveig Steinbrück',
            email: 'Solveig.Steinbrueck@burmester.de',
            phoneNumber: '+49 30 78796812',
            img: solveigSteinbrueckImg,
          },
        ],
        [
          {
            title: 'INSIDE SALES',
            name: 'Christian Langner',
            email: 'Christian.Langner@burmester.de',
            phoneNumber: '+49 30 78796815',
            img: christianLangnerImg,
          },
        ],
      ]
    default:
      return [
        [
          {
            title: 'VERTRIEB AUßENDIENST',
            name: 'Uwe Alander',
            email: 'Uwe.Alander@burmester.de',
            phoneNumber: '+49 174 3189469',
            img: uweAlanderImg,
          },
          {
            title: 'SERVICE & CUSTOMER SUPPORT',
            name: 'Robert Knaut',
            email: 'Robert.Knaut@burmester.de',
            phoneNumber: '+49 30 78796896',
            img: robertKnautImg,
          },
        ],
        [
          {
            title: 'VERTRIEB AUßENDIENST',
            name: 'Jan Hildebrandt',
            email: 'Jan.Hildebrandt@burmester.de',
            phoneNumber: '+49 152 03011876',
            img: janHildebrandtImg,
          },
          {
            title: 'B2B MARKETING',
            name: 'Anna Siewert',
            email: 'Anna.Siewert@burmester.de',
            phoneNumber: '+49 30 78796890',
            img: annaSiewertImg,
          },
        ],
        [
          {
            title: 'VERTRIEB INNENDIENST',
            name: 'Solveig Steinbrück',
            email: 'Solveig.Steinbrueck@burmester.de',
            phoneNumber: '+49 30 78796812',
            img: solveigSteinbrueckImg,
          },
        ],
        [
          {
            title: 'VERTRIEB INNENDIENST',
            name: 'Christian Langner',
            email: 'Christian.Langner@burmester.de',
            phoneNumber: '+49 30 78796815',
            img: christianLangnerImg,
          },
        ],
      ]
  }
}
export const Contact = () => {
  const { i18n } = useTranslation()
  const contactRows = getContactRows(i18n.language)

  const ContactCard = ({
    contact,
  }: {
    contact: {
      title: string
      name: string
      email: string
      phoneNumber: string
      img: string
    }
  }) => {
    const { t } = useTranslation()

    const ContactDetail = ({ children }: { children: React.ReactNode }) => (
      <span className="font-untitled-sans text-gray-10 text-xl tracking-wide lg:break-normal xl:">
        {children}
      </span>
    )

    return (
      <div className="flex flex-col lg:flex-row items-center">
        <div className="flex justify-center w-full xl:w-min">
          <div className="w-36 h-36">
            <img
              src={contact.img}
              className="object-cover rounded-full"
              alt={`${contact.name} photo`}
            />
          </div>
          <div></div>
        </div>
        <div className="w-full xl:w-max">
          <div className="px-11 py-1">
            <div className="flex flex-col">
              <span className="font-untitled-sans text-xl uppercase font-bold text-gray-10 tracking-wide pb-6">
                {contact.title}
              </span>
              <ContactDetail>{contact.name}</ContactDetail>
              <ContactDetail>{`${t('contact.email')}: ${contact.email}`}</ContactDetail>
              <ContactDetail>{`${t('contact.phoneNumber')}: ${contact.phoneNumber}`}</ContactDetail>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="pt-8 pb-8 lg:pb-28">
        <NewPageTitle topText={t('contact.topText')} title={t('contact.title')} backButton>
          {t('contact.pageDescription')}
        </NewPageTitle>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 pb-20">
        {contactRows.map((rowElements) => {
          const [firstContact, secondContact] = rowElements
          return (
            <React.Fragment key={firstContact.name.replace(' ', '_').toLowerCase()}>
              <div className="flex justify-center lg:justify-start mb-8 lg:mb-12">
                <ContactCard contact={firstContact} />
              </div>
              {secondContact && (
                <div className="flex justify-center lg:justify-start mb-8 lg:mb-12">
                  <ContactCard contact={secondContact} />
                </div>
              )}
            </React.Fragment>
          )
        })}
      </div>
    </>
  )
}
